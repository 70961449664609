.formatSection {
    margin-bottom: 76px;
}

.sectionTitle {
    margin-bottom: 18px;
}

.mainText {
    font-size: 20px;
    line-height: 151%;
    color: #ffffff;
    margin-bottom: 24px;
}

.formatSlider {
    padding: 44px 0;
    display: flex;
    gap: 34px;
    border-top: 3px solid #93c11a;
    border-bottom: 3px solid #93c11a;
}

.slideIconWrapper {
    display: flex;
    flex-shrink: 0;
    width: 370px;
    height: 259px;
    align-items: center;
    justify-content: center;
}

.slideImg {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.buttons {
    display: flex;
    gap: 16px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 19px;
    border: 3px solid #ffffff;
    color: #ffffff;
    font-size: 16px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        color: #93c11a;
        border-color: #93c11a;
    }
}

.buttonActive {
    color: #93c11a;
    border-color: #93c11a;
}

.textContent {
    font-size: 16px;
    line-height: 151.69%;
}
