@import '../../styles/theme.scss';

.sectionTeam {
    margin-bottom: 102px;
}

.gallery {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}

.swiperSlide {
    width: 347px;
    height: 253px;
    border: 3px solid #93c11a;
}

.sliderImg {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.prev,
.next {
    display: flex;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 100;
    &:hover {
        transform: translateY(-50%) scale(1.1);
    }
}
