.page404 {
  position: relative;
  max-width: 1920px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
  height: 100vh;
  max-height: 1080px;
  margin: 0 auto;
  margin-bottom: 64px;
}


.page404Text {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}

.page404Title {
  position: relative;
  font-weight: bold;
  font-size: 90px;
  width: 423px;
  text-transform: uppercase;
  line-height: 111.19%;
  margin-bottom: 26px;
}

.page404Subtitle {
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-bottom: 30px;
}