.mainServicesSection {
  margin-bottom: 37px;
}

.mainServicesTitle {
  margin-bottom: 35px;
}

.services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 21px;
}

.card {
  display: flex;
  flex-direction: column;
  border: 3px solid #ffffff;
  position: relative;
  transition: 0.3s;
}

.cardBtn {
  white-space: nowrap;
}

.cardHovered {
  border: 3px solid #93c11a;
  & .cardTitle {
    color: #000107;
    background: #93c11a;
  }
  & .cardBtn {
    color: #000107;
    border: 3px solid #000107;
    background: #93c11a;
    transform: translateX(-50%) scale(1.1);
  }
}

.cardTitle {
  transition: 0.3s;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #000107;
  background-color: #ffffff;
  padding: 10px 20px;
}

.cardContent {
  height: 352px;
  width: 100%;
  overflow: hidden;
}

.cardImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cardBtn {
  transition: 0.3s;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background: #000107;
  color: #ffffff;
  border: 3px solid #ffffff;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.2em;
  position: absolute;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 20px;
  cursor: pointer;
}
