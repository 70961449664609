.sectionTitle {
    margin-bottom: 48px;
}

.typesList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 40px;
    margin-bottom: 100px;
}

.typesItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.typesPic {
    margin-bottom: 12px;
}

.typesTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
