@import "../../styles/theme.scss";

.reviews {
  margin-bottom: 47px;
  &__section {
    margin-bottom: 140px;
  }
  &__title {
    margin-bottom: 57px;
  }
  .slick-list {
    width: 1058px;
  }
  .slick-slide {
    height: 350px;
    overflow: hidden;
  }

  &__arrow {
    display: flex;
    top: 0;
    height: 100%;
    width: 52px;
    position: absolute;
    left: -47px;
    cursor: pointer;
    padding-right: 40px;

    &-prev {
      &:hover {
        padding-right: 38px;
      }
    }

    &-next {
      right: -47px;
      left: auto;
      padding-right: 0;
      padding-left: 40px;
      &:hover {
        padding-left: 38px;
      }
    }
  }

  &__list {
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 1058px;
    margin: 0 auto;

    color: #fff;

    background-color: transparent;
    border-radius: 7px;
    border: 2px solid $green;

    & :global(.slick-dots li button::before) {
      font-size: 84px;
    }
    & :global(.slick-dots) {
      position: absolute;
      bottom: -48px;

      display: block;

      width: 100%;
      padding: 0;
      margin: 0;

      list-style: none;

      text-align: center;
    }
  }

  &__item {
    display: flex;
    width: 1054px;
    min-height: 231px;
    padding: 60px 35px;
    letter-spacing: 0.06rem;
    align-items: center;
  }
}

.review {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__conf {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__text {
    margin-right: 20px;
    font-weight: 400;
    font-size: 21px;
    line-height: 26px;
  }
  &__author {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    &-img {
      display: block;
      width: 154px;
      height: 154px;
      overflow: hidden;
      margin-right: 34px;
      border-radius: 50%;
      border: 2px solid $green;
    }

    &-pic {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: contain;
      text-align: center;
    }
  }
  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
  }

  &__name {
    color: $green;
    font-size: 21px;
    letter-spacing: 0.07rem;
    line-height: 21px;
    margin-bottom: 4px;
  }
  &__company {
    letter-spacing: 0.01rem;
    margin-bottom: 20px;
    font-size: 16px;
  }

  &__position {
    line-height: 1.66;
    letter-spacing: 0.06rem;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}

.confYear {
  color: #000107;
  background: $green;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.2em;
  padding: 6px;
}
