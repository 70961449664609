@import "../../styles/theme.scss";

.projectsSection {
  margin-bottom: 112px;
}

.projectsTitle {
  margin-bottom: 24px;
}

.projectList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.projectItem {
  width: 32%;
  height: 164px;
  border: 3px solid $green;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 26px;
  text-transform: uppercase;
  transition: 0.3s;
  &:hover {
    font-size: 33px;
  }
}

.streamBlock {
  &:hover {
    background: #c02727;
    border: 3px solid #c02727;
    & .firstText,
    .secondText {
      transition: 0.3s;
      color: #ffffff;
    }
  }
}

.cyberBlock {
  &:hover {
    background: #00ff00;
    border: 3px solid #00ff00;
    & .firstText,
    .secondText {
      transition: 0.3s;
      color: #000107;
    }
  }
}

.classicBlock {
  &:hover {
    background: #00b2ff;
    border: 3px solid #00b2ff;

    & .firstText,
    .secondText {
      transition: 0.3s;
      color: #000107;
    }
  }
}

.itemWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemLink {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.classicItem {
  & .firstText {
    color: #00b2ff;
  }
  & .secondText {
    color: #ffffff;
  }
}

.cyberItem {
  & .firstText {
    color: #00ff00;
  }
  & .secondText {
    color: #ffffff;
  }
}

.streamItem {
  & .firstText {
    color: #ed1c24;
  }
  & .secondText {
    color: #ffffff;
  }
}
