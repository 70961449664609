@import "../../styles/theme.scss";

.leaguesSection {
  margin-bottom: 65px;
}

.leaguesTitle {
  margin-bottom: 24px;
}

.leaguesList {
  display: flex;
  margin-right: -20px;
  flex-wrap: wrap;
}

.leaguesItem {
  display: flex;
  border: 3px solid $green;
  padding: 38px 90px;
  margin-right: 20px;
  margin-bottom: 23px;
  align-items: center;
  width: 48%;
}

.leaguesItem-energy {
  &:hover {
    & .leagueTitle {
      color: #fcee21;
    }
    & .arrow {
      transform: translateX(10px);
      fill: #fcee21;
    }
  }
}

.leaguesItem-cyber {
  &:hover {
    & .leagueTitle {
      color: #0099dd;
    }
    & .arrow {
      transform: translateX(10px);
      fill: #0099dd;
    }
  }
}

.leaguesItem-finance {
  &:hover {
    & .leagueTitle {
      color: #00ff00;
    }
    & .arrow {
      transform: translateX(10px);
      fill: #00ff00;
    }
  }
}

.leaguesItem-challenge {
  &:hover {
    & .leagueTitle {
      color: #93c11a;
    }
    & .arrow {
      transform: translateX(10px);
      fill: #93c11a;
    }
  }
}

.leaguesIcon {
  margin-bottom: 20px;
}

.leaguesIconBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 108px;
}

.leaguesEst {
  font-family: "Barlow";
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.leagueTextBlockWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  flex: 1;
}

.leagueTextBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: "Barlow";
  font-style: italic;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 0.05em;
  line-height: 24px;
  margin-bottom: 40px;
}

.leagueTitle {
  transition: 0.3s;
  margin-bottom: 4px;
}

.arrow {
  transition: 0.3s;
}
