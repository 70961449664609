.section {
    margin-bottom: 100px;
}

.sectionTitle {
    margin-bottom: 14px;
}

.menu {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin-bottom: 12px;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 2px;
        display: block;
        height: 3px;
        background-color: #373735;
    }
}

.menuItem {
    font-weight: 700;
    font-size: 20px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    position: relative;
    margin-right: 26px;
    padding: 12px 0;
    transition: 0.3s;
    overflow: hidden;

    &:last-child {
        margin-right: 0;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 2px;
        display: block;
        height: 3px;
        background-color: green;
        transition: 0.3s;
        transform: translateX(-100%);
    }

    &:hover {
        color: #93c11a;
        cursor: pointer;
        &::before {
            transform: translateX(0);
        }
    }

    &.active {
        color: #93c11a;
        &::before {
            transform: translateX(0);
        }
    }
}

.itemWrapper {
    display: flex;
    align-items: center;
}

.services {
    padding-left: 22px;
    margin-bottom: 24px;
    flex-shrink: 0;
}

.service {
    list-style-type: disc;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
}

.tags {
    display: flex;
    align-items: center;
    gap: 10px;
}

.tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 20px;
    border-radius: 18px;

    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: #000107;
    background: #93c11a;
    min-width: 88px;
}

.pic {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    animation-timing-function: ease-in-out;
    animation-name: bounceIn;
    animation-duration: 0.5s;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
