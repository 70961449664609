.complexApproachSection {
    margin-bottom: 90px;
}

.sectionTitle {
    margin-bottom: 60px;
}

.taber {
    display: flex;
    flex-direction: column;
}

.tabsList {
    display: flex;
    position: relative;
    align-self: flex-start;

    &::before {
        position: absolute;
        content: url('./images/afterTabs.svg');
        right: 21px;
        top: 50%;
        transform: translate(100%, -50%);
    }
}

.tabsListWrapper {
    overflow: hidden;
    display: flex;
    border-left: 3px solid #93c11a;
    padding-right: 30px;
}

.tab {
    font-weight: 700;
    font-size: 16px;
    line-height: 111.19%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #93c11a;
    cursor: pointer;
    transition: 0.3s;
    background-color: black;
    border-top: 3px solid #93c11a;
    position: relative;

    &::before {
        transition: 0.3s;
        content: '';
        position: absolute;
        right: -21px;
        top: -3px;
        width: 130%;
        width: 3px;
        height: calc(100% + 3px);
        background: #93c11a;
        transform: skewX(33deg);
        transform-origin: 100% 100%;
        z-index: 10;
    }

    &:first-child {
        &:hover {
            &::before {
                width: calc(100% + 22px);
            }
        }
    }
    &:hover {
        color: black;
        &::before {
            width: calc(100%);
        }
    }

    &:not(:first-child) {
        .tabTitle {
            padding-left: 42px;
            padding-right: 24px;
        }
        &.activeTab {
            &::before {
                width: calc(100%);
            }
        }
    }
}

.tabTitle {
    padding: 6px 32px;
    z-index: 10;
}

.activeTab {
    color: #93c11a;
    cursor: pointer;
    transition: 0.3s;
    color: black;
    &::before {
        width: calc(100% + 22px);
    }
}

.tabContentWrapper {
    display: flex;
    border: 3px solid #93c11a;
    padding: 34px;
    min-height: 360px;
}

.tabContent {
    display: none;
    transition: 0.3s;
}

.tabContentVisible {
    display: flex;
    width: 100%;
}

.content {
    display: grid;
    grid-template-columns: auto 30%;
    align-items: center;
    width: 100%;
    padding-left: 10px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slideContentItem {
    position: relative;
    padding-left: 18px;
    font-size: 20px;
    line-height: 151%;
    color: #ffffff;

    &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        height: 4px;
        width: 4px;
        background-color: white;

        transform: translateY(-50%);
        top: 50%;
        left: 0;
    }
}
