.clients__list {
  width: 1058px;
  margin: 0 auto;
  margin-bottom: 60px;
}

.slider__item {
  width: 168px !important;
  height: 50px;
  padding: 0 15px;
  filter: grayscale(50);
}

.slider__item:hover {
  filter: grayscale(0);
}

.sponsor__logo {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
