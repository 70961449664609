.hero {
    position: relative;
    max-width: 1920px;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    height: 100vh;
    max-height: 1080px;
    margin: 0 auto;
    margin-bottom: 64px;
}

.video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.heroFilter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
}

.heroWrapper {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.heroText {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
}

.heroTitle {
    position: relative;
    font-weight: bold;
    font-size: 90px;
    width: 423px;
    text-transform: uppercase;
    line-height: 111.19%;
    margin-bottom: 26px;
}

.subtitle {
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: bold;
    font-size: 90px;
    transform: translateX(100%);
    transition: 0.3s;
    white-space: nowrap;

    &.classic {
        color: #00b2ff;
    }
    &.cyber {
        color: #00ff00;
    }

    &.stream {
        color: #ed1c24;
    }
}

.heroSubtitle {
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border: 2px solid #ffffff;
    background-color: transparent;
    align-self: flex-start;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    transition: 0.3s;
    &:hover {
        opacity: 0.9;
        .arrow {
            margin-left: 12px;
        }
        padding-right: 14px;
    }
    &:active {
        opacity: 0.8;
        .arrow {
            margin-left: 14px;
        }
        padding-right: 12px;
    }
}

.arrow {
    margin-left: 10px;
    transition: 0.3s;
}

.wrapper {
    width: 100%;
    height: 100%;
}

.closeIcon {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    overflow: hidden;
    &::before,
    &::after {
        content: '';
        display: block;
        height: 2px;
        width: 100px;
        top: 0;
        left: 0;
        transform: rotate(45deg) translate(13px, 35px);
        background-color: #fff;
        z-index: 200;
    }
    &::after {
        transform: rotate(-45deg) translate(-22px, -23px);
    }
}
