$black: #000107;
$green: #93c11a;
$grey: #3e3e3e;
$yellow: #ffff00;

@mixin csgo {
  color: $csgo;
}
@mixin dota {
  color: $dota;
}
@mixin lol {
  color: $lol;
}

.section__title {
  text-align: left;
  color: $yellow;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 0.05em;
  line-height: 1;
  margin-bottom: 32px;
}
.section__title--white {
  color: #ffffff;
}
