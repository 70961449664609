@import '../../styles/theme.scss';

.buttons {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.wrapper {
    padding-bottom: 20px;
}

.button {
    display: flex;
    padding: 20px 10px;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    transition: 0.3s;
    font-size: 15px;
    letter-spacing: 0.1em;
    border: 3px solid #fff;
    color: #fff;
    & svg {
        transition: 0.3s;
    }
    &:hover {
        border: 3px solid #00ff00;
        color: #00ff00;

        & svg {
            fill: #00ff00;
        }
    }
}

.buttonGreen {
    border: 3px solid $green;
    color: $green;
    text-align: center;
}

.titleContainer {
    height: 127px;
    position: relative;
    display: flex;
    margin-bottom: 31px;
    align-items: center;
}

.filter {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
}

.blockBg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: -2;
}

.blockImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.titleWrapper {
    display: flex;
    align-self: center;
    padding-left: 20px;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 92px;
    height: 92px;
    margin-right: 11px;
}

.title {
    font-weight: 700;
    font-size: 90px;
    line-height: 111.19%;
    text-transform: uppercase;
    color: #ffffff;
}

///card

.contentTitle {
    font-weight: 700;
    font-size: 36px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    margin-right: 22px;
}

.contentSubTitle {
    font-weight: 700;
    font-size: 26px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.contentTitleWrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
}

.coloredText {
    color: $green;
}

.textLink {
    color: $green;
    font-size: 16px;
    text-decoration: underline;
    &:hover {
        color: #00ff00;
    }
}

.boldRow {
    font-weight: 700;
    font-size: 20px;
    line-height: 111.19%;
    margin-bottom: 10px;
}

.boldText {
    font-weight: 700;
    font-size: 20px;
    line-height: 111.19%;
}

.arrowLink {
    margin-left: 14px;
    fill: $green;
    transition: 0.3s;
    &:hover {
        margin-left: 18px;
        fill: #00ff00;
    }
}

.regularRow {
    font-size: 18px;
    line-height: 111.19%;
    color: #ffffff;
}
