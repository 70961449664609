.contactsSection {
    background-color: #070b11;
    color: #ffffff;
    padding: 50px 0;
    &__title {
        margin-bottom: 54px;
    }
    a {
        color: #ffffff;
    }
}

.socials__item {
    display: flex;
    height: 63px;
    width: 63px;
    align-items: stretch;
    justify-content: center;
    background-color: #373735;
    margin-bottom: 28px;
    transition: 0.3s;
    &:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }
}

.socials__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.contacts {
    display: flex;
    justify-content: space-between;
    &__social {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        // padding-top: 54px;

        .socials__item {
            margin-right: 0;
            background-color: #373735;

            &:hover {
                background-color: #37373550;
            }
        }
    }
    &__logo {
        display: block;
        width: 181px;
        height: 46px;
        overflow: hidden;
        margin-bottom: 26px;
    }
    &__img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

.contacts__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 21px;
    flex-wrap: wrap;
    width: 100%;
}

.contacts__item {
    white-space: nowrap;
    margin-right: 62px;
    //   margin-bottom: 30px;
    position: relative;
    align-self: flex-end;

    &:last-child {
        margin-right: 0;
        justify-self: flex-end;
        flex: 1;
        justify-content: flex-end;
        display: flex;
    }
}

.contact__desc {
    font-weight: 700;
    white-space: normal;
    &--font-normal {
        font-weight: normal;
    }
}

.contact__tel,
.contact__email {
    display: block;
}

.socials__desc {
    margin-bottom: 10px;
    font-size: 20px;
}
