@import "../../styles/theme.scss";

.menu {
  position: fixed;
  min-width: 1280px;
  z-index: 100;
  width: 100%;
  top: 0;
  font-weight: bold;
  font-size: 16px;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #000000;
    transition: transform 0.2s ease, opacity 0.2s ease;
  }
}

.menuTransparent {
  &.menu {
    &::before {
      transform: translate3d(0, -100%, 0);
      opacity: 0.5;
    }
  }
}

.menuStative {
  position: relative;
}

.menuList {
  margin-left: 58px;
  display: flex;
  flex: 1;
}

.subMenuItem {
  margin-right: 30px;
  font-size: 18px;
}

.menuLink {
  text-transform: uppercase;
  &:hover {
    color: $green;
  }
}

.header {
  position: relative;
  padding: 17px 0;
  display: flex;
  align-items: center;
}

.logo {
  display: block;
  height: 55px;
  width: 79px;
  overflow: hidden;
  flex-shrink: 0;

  &__img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.logoSvg {
  transition: 0.3s;
}

.mainLogo {
  fill: white;
}

.language {
  display: flex;
  background-color: $green;
  color: $black;
  padding: 6px;
  height: 34px;
  width: 34px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:active {
    transform: translateX(2px);
  }
}

.menuSections {
  display: flex;
  align-items: center;
  color: $green;
  padding-right: 37px;
  margin-left: 16px;
  flex-shrink: 0;
  position: relative;
  &::after {
    content: "";
    height: 35px;
    width: 2px;
    background-color: #92c01a;
    right: 0;
    position: absolute;
  }
}

.sectionMenuItem {
  padding-top: 10px;
  margin: 0 12px;
}

.sectionMenuItemActive {
  .classicItem {
    & .firstText {
      color: #00b2ff;
    }
    & .secondText {
      color: #ffffff;
    }
  }
  .cyberItem {
    & .firstText {
      color: #00ff00;
    }
    & .secondText {
      color: #ffffff;
    }
  }
  .streamItem {
    & .firstText {
      color: #ed1c24;
    }
    & .secondText {
      color: #ffffff;
    }
  }
}

.sectionItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  width: 100px;
}

.firstText,
.secondText {
  transition: 0.3s;
}
.firstText {
  line-height: 18px;
}

.classicItem {
  &:hover {
    & .firstText {
      color: #00b2ff;
    }
    & .secondText {
      color: #ffffff;
    }
  }
}

.homeItem {
  color: #8d8c8c;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  &:hover {
    color: white;
    & svg {
      transition: 0.3s;
      fill: white;
    }
  }
}

.arrow {
  margin-bottom: 4px;
}

.cyberItem {
  &:hover {
    & .firstText {
      color: #00ff00;
    }
    & .secondText {
      color: #ffffff;
    }
  }
}

.streamItem {
  &:hover {
    & .firstText {
      color: #ed1c24;
    }
    & .secondText {
      color: #ffffff;
    }
  }
}
