@import "../../styles/theme.scss";

.weSection {
  margin-bottom: 96px;
}

.content {
  display: flex;
  align-items: stretch;
}

.textContent {
  margin-right: 20px;
  width: 826px;
  flex-shrink: 0;
}

.infoContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.logoWrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accent {
  color: $green;
}

.legalInfo {
  padding: 15px;
  border-radius: 10px;
  border: 3px solid green;
  font-size: 17px;
}

.legalInfoLogo {
  font-weight: 700;
}
