@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@1,300;1,700&display=swap");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 21px;
  background-color: #000000;
  color: #ffffff;
  min-width: 100%;

  &--full-screen {
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;
  }
}

.body {
  &--full-screen {
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;
  }
}
.section {
  min-width: 1280px;
  position: relative;
  &--margin {
    margin-bottom: 170px;
  }
}

.wrapper {
  width: 1170px;
  margin: 0 auto;
  height: 100%;
}

h1 {
  font: 500 24px/1.2 "Roboto", "Segoe UI", sans-serif;
}

h2 {
  font: 500 36px/1.2 "Roboto", "Segoe UI", sans-serif;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

h3 {
  font: 500 24px/1.2 "Roboto", "Segoe UI", sans-serif;
}

.anchor-place {
  position: absolute;
  display: block;
  top: -100px;
  height: 0px;
  width: 0px;
  background-color: transparent;
  opacity: 0;
}

// body {
//   overflow: hidden;
//   height: 100vh;
// }
// #root {
//   overflow: hidden;
//   height: 100vh;
// }
