.footerMenu {
    // padding: 0 60px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 18px 0;
}
.footerMenuSection {
    background-color: #8e8e8e;
    color: #ffffff;
    padding: 0;
}

.footerMenuList {
    display: flex;
    white-space: nowrap;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-right: 23px;
    text-transform: uppercase;
}
.footerMenuItem {
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
}
.footerMenuLink {
    color: #ffffff;
    display: block;
    transition: 0.2s;
    &:hover {
        transform: scale(1.05);
    }
}

.footerMenuLinkActive {
    font-weight: 700;
    transform: scale(1.05);
}

.footerMenuCopy {
    align-self: flex-end;
    color: black;
    white-space: nowrap;
}
