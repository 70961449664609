@import '../../styles/theme.scss';

.disciplines {
    margin-bottom: 100px;
}
.disciplinesList {
    display: flex;
    flex-wrap: wrap;
}

.disciplinesItem {
    cursor: pointer;
    display: flex;
    position: relative;
    width: 292px;
    height: 276px;
    color: $green;
    overflow: hidden;
    &:hover {
        & .disciplinesImg {
            transform: scale(1.4);
        }
        & .disciplinesCategory {
            background: rgba(105, 128, 45, 0.5);
            border-color: $green;
            padding-bottom: 8px;
            color: white;
        }

        & svg {
            fill: white;
        }
    }

    & svg {
        transition: 0.3s ease-in-out;
        fill: $green;
    }
}

.cardHovered {
    & .arrowIcon {
        opacity: 1;
        margin-top: 16px;
    }
}

.cardDisabled {
    cursor: unset;
    & .disciplinesCategory {
        background: none;
        backdrop-filter: blur(5px);
    }
    & .disciplinesCategory {
        color: rgba(255, 255, 255, 0.5);
    }

    & svg {
        fill: rgba(255, 255, 255, 0.5);
    }
    &:hover {
        & .disciplinesImg {
            transform: none;
        }
        & .disciplinesCategory {
            background: none;
            border-color: transparent;
            padding-bottom: 0px;
            color: rgba(255, 255, 255, 0.5);
        }

        & svg {
            fill: rgba(255, 255, 255, 0.5);
        }
    }
    & .arrowIcon {
        opacity: 0;
        margin-top: 0px;
    }

    & .counter {
        display: none;
    }
}

.disciplinesCategory {
    transition: all 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px solid transparent;
}

.disciplinesName {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
}
.disciplinesImg {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all ease-in-out 0.3s;
}

.disciplinesIcon {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
}

.disciplinesIconCyber {
    height: 150px;
    width: 150px;
}

.counter {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #93c11a;
    border-radius: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000107;
    padding: 6px;
    z-index: 10;
}

.winterIcon {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
}

.arrowIcon {
    transition: 0.3s;
    margin-top: 0px;
    opacity: 0;
}
