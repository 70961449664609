.section {
    margin-bottom: 70px;
}

.sectionTitle {
    margin-bottom: 52px;
}

.items {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item {
    display: flex;
    flex-direction: column;
}

.itemCount {
    font-weight: 700;
    font-size: 64px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #93c11a;
    margin-bottom: 5px;
}

.itemTitle {
    font-weight: 400;
    font-size: 21px;
    text-align: center;
    color: #ffffff;
}
