.section {
    margin-bottom: 159px;
}

.sectionTitle {
    margin-bottom: 0;
}

.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
}

.gallery {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}

.prev,
.next {
    display: flex;
    cursor: pointer;
    align-items: center;
    z-index: 100;

    &:hover {
        transform: scale(1.1);
    }
}

.swiper {
    border: 3px solid #93c11a;
}

.swiperSlide {
    display: flex;
    align-items: stretch;
    height: auto;
}

.slide {
    padding: 14px 54px 22px 20px;
    display: flex;
    gap: 30px;
    align-items: center;
    background-color: #000;
    width: 100%;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;
}

.slideHeading {
    margin-bottom: 24px;
    text-transform: uppercase;
}

.slideTitle {
    font-weight: 700;
    font-size: 26px;
    line-height: 111.19%;
    letter-spacing: 0.05em;
    color: #ffffff;
}

.slideSubTitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: #ffffff;
}

.rows {
    margin-bottom: 18px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-right: 30px;
}

.contentRow {
    display: flex;
    align-items: baseline;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}

.rowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 24px;
}

.rowContent {
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
}

.watchLink {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: #93c11a;
    margin-left: 4px;

    &:hover {
        & .linkIcon {
            transform: scale(1.05);
        }
    }
}

.linkIcon {
    transition: 0.3s;
    flex-shrink: 0;
}

.rowIcon {
    flex-shrink: 0;
}

.sliderGallery {
    display: grid;
    grid-template-rows: repeat(2, 157px);
    grid-template-columns: repeat(2, 235px);
    gap: 7px;
    flex: 1;
    justify-content: flex-end;
}

.galleryPic {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: grey;
}

.galleryImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
