@import '../../styles/theme.scss';

.sectionValues {
    margin-bottom: 124px;
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 22px;
}

.card {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.icon {
    height: 70px;
    width: 70px;
    display: flex;
    flex-shrink: 0;
    margin-right: 21px;
    align-items: center;
    justify-content: center;
}

.text {
    font-size: 18px;
    color: #ffffff;
}

.textAccent {
    font-weight: 700;
    color: $green;
}
