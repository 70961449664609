.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  padding: 40px;
  background: rgba(0, 1, 7, 0.8);
  backdrop-filter: blur(11px);
  overflow: auto;
}
